
import { Watch, Emit, Prop, Component, Vue, InjectReactive, Ref, PropSync } from "vue-property-decorator";
import UploadImg from "@/components/uploadImg.vue";
import { brandMiniConfig, saveBrandMiniConfig } from "@/api/prescriptionDb";
import { Form } from "element-ui";
import { IHomeInstance } from "@/types/global";

@Component({
  components: { UploadImg }
})
export default class MiniSetting extends Vue {
  @Prop() readonly brandInfo!: any;
  @PropSync("dialogVisible") dialogVisibleSync!: Boolean;
  @Ref("formRef") formRef!: Form;
  @InjectReactive() HomeInstance !: IHomeInstance;

  merchantId = this.$route.query.merchantId || "";;
  brandName = this.$route.query.brandName || "";

  form = {
    id: "",
    appId: "",
    appSecret: "",
    thirdMiniName: "",
    pulseTakingUrl: "",
    status: true
  }

  rules = {
    appId: [{ required: true, message: "请输入Appid" }],
    appSecret: [{ required: true, message: "请输入AppSecret" }],
    thirdMiniName: [{ required: true, message: "请输入小程序名称" }],
    pulseTakingUrl: [{ required: true, message: "请输入脉诊链接" }],
    miniLogo: [{ required: true, message: "请上传小程序logo" }],
  }

  saveLoading = false;

  @Watch("merchantId", { immediate: true, deep: true })
  initForm() {
    brandMiniConfig(this.merchantId).then((res) => {
      const config = res.data || {};
      if (Reflect.ownKeys(config).length <= 0) return;
      Object.assign(this.form, { ...config, status: Boolean(config.status) })
    })
  }

  handleClose() {
    this.formRef.resetFields();
    this.form.id = "";
    this.HomeInstance.tabRemove("merchantMiniSetting");

  }

  save() {
    this.formRef.validate().then(() => {
      console.log(this.form);
      const params = { ...this.form, status: Number(this.form.status), merchantId: this.merchantId }
      this.saveLoading = true;
      saveBrandMiniConfig(params).then(() => {
        this.$message.success("操作成功");
        this.handleClose();
        this.syncList();
      }).finally(() => {
        this.saveLoading = false;
      })
    })
  }

  @Emit("syncList")
  syncList() {
    return true;
  }
}
